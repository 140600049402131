export const contactInfo = {
    address: 'Rr.Myslym Shyri, <br> Kryeqzimi me  Muhamet Gjolleshen',
    phone: '069 70 21 101',
    email: 'kontakt@himatravel.com',
    orari: 'E Hene – E Shtune <br> Ora 08:00 – 21:00'
}

export const pages = [
    {title: 'Bileta', link: ''},
    {title: 'Bileta Avioni', link: ''},
    {title: 'Udhëtime Turistike', link: ''},
    {title: 'Paketa Turistike', link: ''},
]

export const headPages = [
    // {title: 'Inspire', link: '/inspiration', query: '?origin=1'},
    // {title: 'Paketa', link: '/search-0-to-0'},
]

export const socialNetworks = [
    {title: 'facebook', link: 'https://www.facebook.com/HimaTravelTours/?locale=sq_AL'},
    {title: 'instagram', link: 'https://www.instagram.com/agjensi_udhetimi_hima_travel'},
    {title: 'twitter', link: 'https://twitter.com/himatravel'},
    {title: 'youtube', link: 'https://www.youtube.com'},
]

export const privacyLinks = [
    {title: 'Politikat e privatësisë', link: 'https://himatravel.com/politikat-e-privatesise/'},
    {title: 'Kontrata me klientin', link: 'https://himatravel.com/kontrata-me-klientin/'},
    {title: 'Rreth Nesh', link: 'https://himatravel.com/rreth-nesh/'},
]


export const testLiveData = [
    {
        "id": 812,
        "outbound_flight_id": 825,
        "inbound_flight_id": 826,
        "hotel_data_id": 932,
        "commission": "20.00",
        "total_price": "72.54",
        "created_at": "2024-04-13T13:49:33.000000Z",
        "updated_at": "2024-04-13T13:49:33.000000Z",
        "package_config_id": null,
        "hotel_data": {
            "id": 932,
            "hotel_id": 85882,
            "check_in_date": "2024-06-12",
            "number_of_nights": 2,
            "room_count": 1,
            "adults": 2,
            "children": 0,
            "package_config_id": 0,
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "hotel": {
                "id": 85882,
                "hotel_id": 30092,
                "name": "ZENIT",
                "address": "Plaza de San Pedro 5-6, Murcia, ES",
                "phone": "34-968-214742",
                "fax": "34-968-216765",
                "stars": 3,
                "stars_id": 5,
                "longitude": "-1.133337",
                "latitude": "37.984234",
                "is_apartment": 0,
                "giata_code": "49592",
                "city_id": 1254,
                "city": "MURCIA PROVINCE",
                "iso_code": "ES",
                "country": "SPAIN",
                "country_id": 24,
                "review_score": null,
                "review_count": null,
                "destination_id": null,
                "created_at": "2023-09-07T11:14:56.000000Z",
                "updated_at": "2023-09-07T11:14:56.000000Z",
                "description": null,
                "hotel_photos": []
            },
            "offers": [
                {
                    "id": 8095,
                    "hotel_data_id": 932,
                    "room_basis": "RO",
                    "room_type": "DOUBLE",
                    "price": "121.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8096,
                    "hotel_data_id": 932,
                    "room_basis": "RO",
                    "room_type": "Double or Twin STANDARD",
                    "price": "125.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8097,
                    "hotel_data_id": 932,
                    "room_basis": "RO",
                    "room_type": "Twin/Double room",
                    "price": "135.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8098,
                    "hotel_data_id": 932,
                    "room_basis": "BB",
                    "room_type": "DOUBLE",
                    "price": "154.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8099,
                    "hotel_data_id": 932,
                    "room_basis": "BB",
                    "room_type": "Double or Twin STANDARD",
                    "price": "158.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8100,
                    "hotel_data_id": 932,
                    "room_basis": "BB",
                    "room_type": "Twin/Double room",
                    "price": "172.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                }
            ]
        },
        "outbound_flight": {
            "id": 825,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-12T16:10:00.000000Z",
            "arrival": "2024-06-12T17:50:00.000000Z",
            "price": "26.28",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.28, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-12T17:50:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-12T16:10:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        },
        "inbound_flight": {
            "id": 826,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-14T16:30:00.000000Z",
            "arrival": "2024-06-14T18:10:00.000000Z",
            "price": "26.26",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.26, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-14T18:10:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-14T16:30:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        }
    },
    {
        "id": 813,
        "outbound_flight_id": 825,
        "inbound_flight_id": 826,
        "hotel_data_id": 933,
        "commission": "20.00",
        "total_price": "72.54",
        "created_at": "2024-04-13T13:49:33.000000Z",
        "updated_at": "2024-04-13T13:49:33.000000Z",
        "package_config_id": null,
        "hotel_data": {
            "id": 933,
            "hotel_id": 127986,
            "check_in_date": "2024-06-12",
            "number_of_nights": 2,
            "room_count": 1,
            "adults": 2,
            "children": 0,
            "package_config_id": 0,
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "hotel": {
                "id": 127986,
                "hotel_id": 128105,
                "name": "ROMA TOR VERGATA",
                "address": "Via Vico Vigano 24, Rome, IT",
                "phone": "06206621",
                "fax": "",
                "stars": 4,
                "stars_id": 7,
                "longitude": "12.630335",
                "latitude": "41.866461",
                "is_apartment": 0,
                "giata_code": "50787",
                "city_id": 1661,
                "city": "ROME",
                "iso_code": "IT",
                "country": "ITALY",
                "country_id": 37,
                "review_score": null,
                "review_count": null,
                "destination_id": 1,
                "created_at": "2023-09-07T11:15:00.000000Z",
                "updated_at": "2023-09-18T17:15:10.000000Z",
                "description": null,
                "hotel_photos": [
                    {
                        "id": 17,
                        "hotel_id": 127986,
                        "file_path": "b1ByThgv1Lyj431guywnNFsgYx0k8T-metacGhvdG8yLmpwZWc=-.jpg",
                        "created_at": "2023-12-02T16:58:41.000000Z",
                        "updated_at": "2023-12-02T16:58:41.000000Z"
                    },
                    {
                        "id": 18,
                        "hotel_id": 127986,
                        "file_path": "Z4oBXMxtVhq0KillskBjL2448rcyzx-metaaW1hZ2UgKDIpLnBuZw==-.png",
                        "created_at": "2023-12-02T16:58:41.000000Z",
                        "updated_at": "2023-12-02T16:58:41.000000Z"
                    }
                ]
            },
            "offers": [
                {
                    "id": 8101,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Standard Room 1 King Bed Non Smoking NON-REFUNDABLE",
                    "price": "133.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8102,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Room 1 King Bed Accessible Non Smoking NON-REFUNDABLE",
                    "price": "133.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8103,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Standard Room 1 King Bed Non Smoking",
                    "price": "138.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8104,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Room 1 King Bed Accessible Non Smoking",
                    "price": "138.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8105,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Double Room 2 Double Beds Non Smoking NON-REFUNDABLE",
                    "price": "147.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8106,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Standard Double Room 2 Double Beds Accessible NON-REFUNDABLE",
                    "price": "147.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8107,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Double Room 2 Double Beds Non Smoking",
                    "price": "152.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8108,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Standard Double Room 2 Double Beds Accessible",
                    "price": "152.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8109,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Double Room with Two Double Beds - Disability Access - Non-refundable ",
                    "price": "160.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8110,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Signature Suite 1 King Bed Non Smoking Poolside NON-REFUNDABLE",
                    "price": "161.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8111,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Signature Suite 1 King Bed Non Smoking Poolside",
                    "price": "167.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8112,
                    "hotel_data_id": 933,
                    "room_basis": "BB",
                    "room_type": "DOUBLE KING SIZE BED - NON REFUNDUBLE",
                    "price": "172.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8113,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Signature Suite Multiple Beds Non Smoking NON-REFUNDABLE",
                    "price": "175.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8114,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Signature Suite Multiple Beds Non Smoking",
                    "price": "181.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8115,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "2 Double Beds  Nonsmoking  Accessible - NON REFUNDUBLE",
                    "price": "183.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8116,
                    "hotel_data_id": 933,
                    "room_basis": "BB",
                    "room_type": "1 King Bed  Nonsmoking  Accessible",
                    "price": "186.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8117,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Double Room with Two Double Beds - Disability Access ",
                    "price": "187.00",
                    "reservation_deadline": "2024-06-11",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8118,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Standard Double Suite - Non-refundable ",
                    "price": "191.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8119,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "1 King Bed  Suite  Nonsmoking - NON REFUNDUBLE",
                    "price": "201.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8120,
                    "hotel_data_id": 933,
                    "room_basis": "BB",
                    "room_type": "2 Double Beds  Nonsmoking",
                    "price": "206.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8121,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "2 Double Beds  Suite  Nonsmoking - NON REFUNDUBLE",
                    "price": "218.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8122,
                    "hotel_data_id": 933,
                    "room_basis": "BB",
                    "room_type": "1 King Bed  Nonsmoking  Accessible",
                    "price": "218.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8123,
                    "hotel_data_id": 933,
                    "room_basis": "RO",
                    "room_type": "Standard Double Suite ",
                    "price": "223.00",
                    "reservation_deadline": "2024-06-11",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8124,
                    "hotel_data_id": 933,
                    "room_basis": "BB",
                    "room_type": "1 King Bed  Suite  No Smoking",
                    "price": "226.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8125,
                    "hotel_data_id": 933,
                    "room_basis": "BB",
                    "room_type": "2 Double Beds  Nonsmoking",
                    "price": "241.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8126,
                    "hotel_data_id": 933,
                    "room_basis": "BB",
                    "room_type": "Suite 2 Double Beds",
                    "price": "245.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8127,
                    "hotel_data_id": 933,
                    "room_basis": "BB",
                    "room_type": "1 King Bed  Suite  No Smoking",
                    "price": "265.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8128,
                    "hotel_data_id": 933,
                    "room_basis": "BB",
                    "room_type": "Suite 2 Double Beds",
                    "price": "288.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                }
            ]
        },
        "outbound_flight": {
            "id": 825,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-12T16:10:00.000000Z",
            "arrival": "2024-06-12T17:50:00.000000Z",
            "price": "26.28",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.28, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-12T17:50:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-12T16:10:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        },
        "inbound_flight": {
            "id": 826,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-14T16:30:00.000000Z",
            "arrival": "2024-06-14T18:10:00.000000Z",
            "price": "26.26",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.26, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-14T18:10:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-14T16:30:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        }
    },
    {
        "id": 814,
        "outbound_flight_id": 825,
        "inbound_flight_id": 826,
        "hotel_data_id": 934,
        "commission": "20.00",
        "total_price": "72.54",
        "created_at": "2024-04-13T13:49:33.000000Z",
        "updated_at": "2024-04-13T13:49:33.000000Z",
        "package_config_id": null,
        "hotel_data": {
            "id": 934,
            "hotel_id": 157239,
            "check_in_date": "2024-06-12",
            "number_of_nights": 2,
            "room_count": 1,
            "adults": 2,
            "children": 0,
            "package_config_id": 0,
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "hotel": {
                "id": 157239,
                "hotel_id": 30085,
                "name": "LAGOSMAR",
                "address": "Rua Dr. Faria e Silva, N13, Lagos, PT",
                "phone": "00351282763722",
                "fax": "351767324",
                "stars": 3,
                "stars_id": 5,
                "longitude": "-8.673274",
                "latitude": "37.103474",
                "is_apartment": 0,
                "giata_code": "2114",
                "city_id": 1032,
                "city": "LAGOS",
                "iso_code": "PT",
                "country": "PORTUGAL",
                "country_id": 68,
                "review_score": null,
                "review_count": null,
                "destination_id": null,
                "created_at": "2023-09-07T11:15:03.000000Z",
                "updated_at": "2023-09-07T11:15:03.000000Z",
                "description": null,
                "hotel_photos": []
            },
            "offers": [
                {
                    "id": 8129,
                    "hotel_data_id": 934,
                    "room_basis": "RO",
                    "room_type": "Standard Double Room NON-REFUNDABLE",
                    "price": "206.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8130,
                    "hotel_data_id": 934,
                    "room_basis": "RO",
                    "room_type": "Standard Double Room",
                    "price": "229.00",
                    "reservation_deadline": "2024-06-07",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8131,
                    "hotel_data_id": 934,
                    "room_basis": "RO",
                    "room_type": "Superior Double Room NON-REFUNDABLE",
                    "price": "234.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8132,
                    "hotel_data_id": 934,
                    "room_basis": "RO",
                    "room_type": "Deluxe Double Room NON-REFUNDABLE",
                    "price": "256.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8133,
                    "hotel_data_id": 934,
                    "room_basis": "RO",
                    "room_type": "Superior Double Room",
                    "price": "260.00",
                    "reservation_deadline": "2024-06-07",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8134,
                    "hotel_data_id": 934,
                    "room_basis": "RO",
                    "room_type": "Deluxe Double Room",
                    "price": "285.00",
                    "reservation_deadline": "2024-06-07",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                }
            ]
        },
        "outbound_flight": {
            "id": 825,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-12T16:10:00.000000Z",
            "arrival": "2024-06-12T17:50:00.000000Z",
            "price": "26.28",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.28, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-12T17:50:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-12T16:10:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        },
        "inbound_flight": {
            "id": 826,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-14T16:30:00.000000Z",
            "arrival": "2024-06-14T18:10:00.000000Z",
            "price": "26.26",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.26, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-14T18:10:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-14T16:30:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        }
    },
    {
        "id": 815,
        "outbound_flight_id": 825,
        "inbound_flight_id": 826,
        "hotel_data_id": 935,
        "commission": "20.00",
        "total_price": "72.54",
        "created_at": "2024-04-13T13:49:33.000000Z",
        "updated_at": "2024-04-13T13:49:33.000000Z",
        "package_config_id": null,
        "hotel_data": {
            "id": 935,
            "hotel_id": 157296,
            "check_in_date": "2024-06-12",
            "number_of_nights": 2,
            "room_count": 1,
            "adults": 2,
            "children": 0,
            "package_config_id": 0,
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "hotel": {
                "id": 157296,
                "hotel_id": 30090,
                "name": "ALIF CAMPO PEQUENO",
                "address": "Avenida Joao XXI, 80, Lisbon, PT",
                "phone": "351-21-7826210",
                "fax": "351-21-7954116",
                "stars": 3,
                "stars_id": 5,
                "longitude": "-9.14319",
                "latitude": "38.74198",
                "is_apartment": 0,
                "giata_code": "7373",
                "city_id": 1104,
                "city": "LISBON",
                "iso_code": "PT",
                "country": "PORTUGAL",
                "country_id": 68,
                "review_score": null,
                "review_count": null,
                "destination_id": null,
                "created_at": "2023-09-07T11:15:03.000000Z",
                "updated_at": "2023-09-07T11:15:03.000000Z",
                "description": null,
                "hotel_photos": []
            },
            "offers": [
                {
                    "id": 8135,
                    "hotel_data_id": 935,
                    "room_basis": "RO",
                    "room_type": "Twin Standard",
                    "price": "210.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8136,
                    "hotel_data_id": 935,
                    "room_basis": "RO",
                    "room_type": "Double Standard",
                    "price": "232.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8137,
                    "hotel_data_id": 935,
                    "room_basis": "RO",
                    "room_type": "Twin Room ",
                    "price": "252.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8138,
                    "hotel_data_id": 935,
                    "room_basis": "BB",
                    "room_type": "Twin Standard",
                    "price": "258.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8139,
                    "hotel_data_id": 935,
                    "room_basis": "RO",
                    "room_type": "Double Room ",
                    "price": "271.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8140,
                    "hotel_data_id": 935,
                    "room_basis": "BB",
                    "room_type": "Double Standard",
                    "price": "280.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8141,
                    "hotel_data_id": 935,
                    "room_basis": "RO",
                    "room_type": "Superior Twin Room",
                    "price": "282.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8142,
                    "hotel_data_id": 935,
                    "room_basis": "BB",
                    "room_type": "Twin Room - Breakfast included in the price ",
                    "price": "294.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8143,
                    "hotel_data_id": 935,
                    "room_basis": "RO",
                    "room_type": "Superior Double Room",
                    "price": "299.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8144,
                    "hotel_data_id": 935,
                    "room_basis": "BB",
                    "room_type": "Double Room - Breakfast included in the price ",
                    "price": "314.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8145,
                    "hotel_data_id": 935,
                    "room_basis": "BB",
                    "room_type": "Superior Twin Room - Breakfast included in the price ",
                    "price": "333.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8146,
                    "hotel_data_id": 935,
                    "room_basis": "BB",
                    "room_type": "Superior Double Room - Breakfast included in the price ",
                    "price": "352.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                }
            ]
        },
        "outbound_flight": {
            "id": 825,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-12T16:10:00.000000Z",
            "arrival": "2024-06-12T17:50:00.000000Z",
            "price": "26.28",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.28, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-12T17:50:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-12T16:10:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        },
        "inbound_flight": {
            "id": 826,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-14T16:30:00.000000Z",
            "arrival": "2024-06-14T18:10:00.000000Z",
            "price": "26.26",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.26, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-14T18:10:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-14T16:30:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        }
    },
    {
        "id": 816,
        "outbound_flight_id": 825,
        "inbound_flight_id": 826,
        "hotel_data_id": 936,
        "commission": "20.00",
        "total_price": "72.54",
        "created_at": "2024-04-13T13:49:33.000000Z",
        "updated_at": "2024-04-13T13:49:33.000000Z",
        "package_config_id": null,
        "hotel_data": {
            "id": 936,
            "hotel_id": 53269,
            "check_in_date": "2024-06-12",
            "number_of_nights": 2,
            "room_count": 1,
            "adults": 2,
            "children": 0,
            "package_config_id": 0,
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "hotel": {
                "id": 53269,
                "hotel_id": 30083,
                "name": "BEST WESTERN PLUS ST. RAPHAEL",
                "address": "Adenauerallee 41, Hamburg, DE",
                "phone": "49-40-248200",
                "fax": "49 40 24820333",
                "stars": 4,
                "stars_id": 7,
                "longitude": "10.016264",
                "latitude": "53.552794",
                "is_apartment": 0,
                "giata_code": "95595",
                "city_id": 764,
                "city": "HAMBURG",
                "iso_code": "DE",
                "country": "GERMANY",
                "country_id": 20,
                "review_score": null,
                "review_count": null,
                "destination_id": null,
                "created_at": "2023-09-07T11:13:47.000000Z",
                "updated_at": "2023-09-07T11:13:47.000000Z",
                "description": null,
                "hotel_photos": []
            },
            "offers": [
                {
                    "id": 8147,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "1 Double Bed Standard Room Flat Screen Television Work Desk Safe Free W-Lan Air-Conditioned",
                    "price": "249.00",
                    "reservation_deadline": "2023-06-11",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8148,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "1 Double Bed Standard Room Flat Screen Television Work Desk Safe Free W-Lan Air-Conditioned",
                    "price": "259.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8149,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "1 Double Bed Comfort Air Conditioning Stereo System Flat Screen Television Free W-Lan Work Desk",
                    "price": "264.00",
                    "reservation_deadline": "2023-06-11",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8150,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Double room",
                    "price": "273.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8151,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "1 Double Bed Comfort Air Conditioning Stereo System Flat Screen Television Free W-Lan Work Desk",
                    "price": "274.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8152,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Standard Double Room ",
                    "price": "275.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8153,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Standard Room 1 Double Bed NON-REFUNDABLE",
                    "price": "276.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8154,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "1 Double Bed Comfort Plus Work Desk Stereo System Safe With Plug Free W-Lan Air-Conditioned",
                    "price": "279.00",
                    "reservation_deadline": "2023-06-11",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8155,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Double room",
                    "price": "284.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8156,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Comfort Room 1 Double Bed NON-REFUNDABLE",
                    "price": "292.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8157,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "1 Double Bed Comfort Plus Work Desk Stereo System Safe With Plug Free W-Lan Air-Conditioned",
                    "price": "292.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8158,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Standard Double Room ",
                    "price": "307.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8159,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Standard Room 1 Double Bed",
                    "price": "308.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8160,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Comfort Plus Double Room  ",
                    "price": "310.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8161,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Comfort Room 1 Double Bed (Comfort Plus) NON-REFUNDABLE",
                    "price": "311.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8162,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Comfort Room 1 Double Bed",
                    "price": "312.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8163,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "1 Double Bed Junior Suite Work Desk Stereo System Safe With Plug Free W-Lan Air-Conditioned",
                    "price": "320.00",
                    "reservation_deadline": "2023-06-11",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8164,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "1 Double Bed Standard Room Flat Screen Television Work Desk Safe Free W-Lan Air-Conditioned",
                    "price": "323.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8165,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "1 Double Bed  Standard Room  Flat Screen Television  Work Desk  Safe  Free W-Lan  Air-Conditioned",
                    "price": "332.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8166,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "1 Double Bed Junior Suite Work Desk Stereo System Safe With Plug Free W-Lan Air-Conditioned",
                    "price": "332.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8167,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "1 Double Bed Comfort Air Conditioning Stereo System Flat Screen Television Free W-Lan Work Desk",
                    "price": "338.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8168,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Comfort Plus Double Room  ",
                    "price": "346.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8169,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Comfort Room 1 Double Bed (Comfort Plus)",
                    "price": "347.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8170,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "1 Double Bed  Comfort  Air Conditioning  Stereo System  Flat Screen Television  Free W-Lan  Work Desk",
                    "price": "347.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8171,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Double room",
                    "price": "354.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8172,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Junior Suite ",
                    "price": "354.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8173,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Junior Suite 1 Double Bed NON-REFUNDABLE",
                    "price": "355.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8174,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Standard Double Room - Breakfast included in the price ",
                    "price": "355.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8175,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Standard Room 1 Double Bed NON-REFUNDABLE",
                    "price": "356.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8176,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "1 Double Bed Comfort Plus Work Desk Stereo System Safe With Plug Free W-Lan Air-Conditioned",
                    "price": "356.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8177,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "1 Double Bed  Comfort Plus  Work Desk  Stereo System  Safe With Plug  Free W-Lan  Air-Conditioned",
                    "price": "366.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8178,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Comfort Double Room  - Breakfast included in the price ",
                    "price": "371.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8179,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Comfort Room 1 Double Bed NON-REFUNDABLE",
                    "price": "371.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8180,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Standard Double Room - Breakfast included in the price ",
                    "price": "387.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8181,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Standard Room 1 Double Bed",
                    "price": "387.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8182,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Comfort Plus Double Room  - Breakfast included in the price ",
                    "price": "390.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8183,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Comfort Room 1 Double Bed (Comfort Plus) NON-REFUNDABLE",
                    "price": "390.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8184,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Junior Suite ",
                    "price": "396.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8185,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "1 Double Bed Junior Suite Work Desk Stereo System Safe With Plug Free W-Lan Air-Conditioned",
                    "price": "396.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8186,
                    "hotel_data_id": 936,
                    "room_basis": "RO",
                    "room_type": "Junior Suite 1 Double Bed",
                    "price": "396.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8187,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Comfort Room 1 Double Bed",
                    "price": "405.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8188,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "1 Double Bed  Junior Suite  Work Desk  Stereo System  Safe With Plug  Free W-Lan  Air-Conditioned",
                    "price": "407.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8189,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Comfort Plus Double Room  - Breakfast included in the price ",
                    "price": "426.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8190,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Comfort Room 1 Double Bed (Comfort Plus)",
                    "price": "426.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8191,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Junior Suite - Breakfast included in the price ",
                    "price": "434.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8192,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Junior Suite 1 Double Bed NON-REFUNDABLE",
                    "price": "434.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8193,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Junior Suite - Breakfast included in the price ",
                    "price": "475.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8194,
                    "hotel_data_id": 936,
                    "room_basis": "BB",
                    "room_type": "Junior Suite 1 Double Bed",
                    "price": "476.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                }
            ]
        },
        "outbound_flight": {
            "id": 825,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-12T16:10:00.000000Z",
            "arrival": "2024-06-12T17:50:00.000000Z",
            "price": "26.28",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.28, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-12T17:50:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-12T16:10:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        },
        "inbound_flight": {
            "id": 826,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-14T16:30:00.000000Z",
            "arrival": "2024-06-14T18:10:00.000000Z",
            "price": "26.26",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.26, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-14T18:10:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-14T16:30:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        }
    },
    {
        "id": 817,
        "outbound_flight_id": 825,
        "inbound_flight_id": 826,
        "hotel_data_id": 937,
        "commission": "20.00",
        "total_price": "72.54",
        "created_at": "2024-04-13T13:49:33.000000Z",
        "updated_at": "2024-04-13T13:49:33.000000Z",
        "package_config_id": null,
        "hotel_data": {
            "id": 937,
            "hotel_id": 181052,
            "check_in_date": "2024-06-12",
            "number_of_nights": 2,
            "room_count": 1,
            "adults": 2,
            "children": 0,
            "package_config_id": 0,
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "hotel": {
                "id": 181052,
                "hotel_id": 127986,
                "name": "HOLIDAY INN EXPRESS & SUITES GAHANNA/COLUMBUS AIRPORT E",
                "address": "460 Waterbury Ct, Columbus, US",
                "phone": "1-614-4288678",
                "fax": "1-614-4289839",
                "stars": 3,
                "stars_id": 5,
                "longitude": "-82.865616",
                "latitude": "40.007614",
                "is_apartment": 0,
                "giata_code": "117908",
                "city_id": 432,
                "city": "COLUMBUS (OH)",
                "iso_code": "US",
                "country": "UNITED STATES",
                "country_id": 89,
                "review_score": null,
                "review_count": null,
                "destination_id": null,
                "created_at": "2023-09-07T11:15:06.000000Z",
                "updated_at": "2023-09-07T11:15:06.000000Z",
                "description": null,
                "hotel_photos": []
            },
            "offers": [
                {
                    "id": 8195,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Standard Room NON-REFUNDABLE",
                    "price": "262.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8196,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Standard Room 1 King Bed with Sofa bed NON-REFUNDABLE",
                    "price": "262.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8197,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Standard Room 2 Queen Beds Accessible Non Smoking (Communications) NON-REFUNDABLE",
                    "price": "262.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8198,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Room 2 Queen Beds NON-REFUNDABLE",
                    "price": "262.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8199,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Standard Room 1 King Bed (LEISURE) NON-REFUNDABLE",
                    "price": "262.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8200,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "1 King Bed Standard - NON REFUNDUBLE",
                    "price": "270.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8201,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "1 King Bed Leisure Non-Smoking",
                    "price": "271.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8202,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "1 King Bed With Sofa Bed Non-Smoking",
                    "price": "271.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8203,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "DOUBLE TWO QUEEN BEDS - NON REFUNDUBLE",
                    "price": "274.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8204,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "DOUBLE KING SIZE BED - NON REFUNDUBLE",
                    "price": "281.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8205,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Room Selected at Check-In - Non-refundable - Breakfast included in the price ",
                    "price": "284.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8206,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "King Room - Non-refundable - Breakfast included in the price ",
                    "price": "284.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8207,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Standard Queen Room with Tub - Communications Accessible - Non-refundable - Breakfast included in the price ",
                    "price": "284.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8208,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Queen Room with Two Queen Beds - Non-refundable - Breakfast included in the price ",
                    "price": "284.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8209,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Standard Room",
                    "price": "307.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8210,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Standard Room 1 King Bed with Sofa bed",
                    "price": "307.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8211,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Standard Room 2 Queen Beds Accessible Non Smoking (Communications)",
                    "price": "307.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8212,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Room 2 Queen Beds",
                    "price": "307.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8213,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Standard Room 1 King Bed (LEISURE)",
                    "price": "307.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8214,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "1 King Bed Standard",
                    "price": "316.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8215,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "1 King Bed Leisure Non-Smoking",
                    "price": "318.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8216,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "1 King Bed With Sofa Bed Non-Smoking",
                    "price": "318.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8217,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Standard Queen Room with Tub - Communications Accessible - Breakfast included in the price ",
                    "price": "333.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8218,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "King Room - Breakfast included in the price ",
                    "price": "333.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8219,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Room Selected at Check-In - Breakfast included in the price ",
                    "price": "333.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8220,
                    "hotel_data_id": 937,
                    "room_basis": "RO",
                    "room_type": "Queen Room with Two Queen Beds ",
                    "price": "333.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8221,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "1 King Bed Suite Sofa Bed",
                    "price": "369.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8222,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "1 King Bed Suite",
                    "price": "369.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8223,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "Suite 1 King Bed with Sofa bed",
                    "price": "386.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8224,
                    "hotel_data_id": 937,
                    "room_basis": "BB",
                    "room_type": "King Suite - Non-Smoking - Breakfast included in the price ",
                    "price": "388.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                }
            ]
        },
        "outbound_flight": {
            "id": 825,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-12T16:10:00.000000Z",
            "arrival": "2024-06-12T17:50:00.000000Z",
            "price": "26.28",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.28, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-12T17:50:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-12T16:10:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        },
        "inbound_flight": {
            "id": 826,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-14T16:30:00.000000Z",
            "arrival": "2024-06-14T18:10:00.000000Z",
            "price": "26.26",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.26, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-14T18:10:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-14T16:30:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        }
    },
    {
        "id": 818,
        "outbound_flight_id": 825,
        "inbound_flight_id": 826,
        "hotel_data_id": 938,
        "commission": "20.00",
        "total_price": "72.54",
        "created_at": "2024-04-13T13:49:33.000000Z",
        "updated_at": "2024-04-13T13:49:33.000000Z",
        "package_config_id": null,
        "hotel_data": {
            "id": 938,
            "hotel_id": 53288,
            "check_in_date": "2024-06-12",
            "number_of_nights": 2,
            "room_count": 1,
            "adults": 2,
            "children": 0,
            "package_config_id": 0,
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "hotel": {
                "id": 53288,
                "hotel_id": 30081,
                "name": "CITY HOUSE",
                "address": "Pulverteich 25, Hamburg, DE",
                "phone": "49-40-2800810",
                "fax": "49-40-2801838",
                "stars": 4,
                "stars_id": 7,
                "longitude": "10.01507",
                "latitude": "53.55334",
                "is_apartment": 0,
                "giata_code": "10438",
                "city_id": 764,
                "city": "HAMBURG",
                "iso_code": "DE",
                "country": "GERMANY",
                "country_id": 20,
                "review_score": null,
                "review_count": null,
                "destination_id": null,
                "created_at": "2023-09-07T11:13:47.000000Z",
                "updated_at": "2023-09-07T11:13:47.000000Z",
                "description": null,
                "hotel_photos": []
            },
            "offers": [
                {
                    "id": 8225,
                    "hotel_data_id": 938,
                    "room_basis": "RO",
                    "room_type": "Standard Double Room ",
                    "price": "284.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8226,
                    "hotel_data_id": 938,
                    "room_basis": "RO",
                    "room_type": "Comfort Double Room ",
                    "price": "313.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8227,
                    "hotel_data_id": 938,
                    "room_basis": "RO",
                    "room_type": "Standard Double Room ",
                    "price": "314.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8228,
                    "hotel_data_id": 938,
                    "room_basis": "RO",
                    "room_type": "Comfort Double Room ",
                    "price": "346.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8229,
                    "hotel_data_id": 938,
                    "room_basis": "RO",
                    "room_type": "Double Room with Sofa Bed ",
                    "price": "368.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8230,
                    "hotel_data_id": 938,
                    "room_basis": "BB",
                    "room_type": "Standard Double Room - Breakfast included in the price ",
                    "price": "370.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8231,
                    "hotel_data_id": 938,
                    "room_basis": "BB",
                    "room_type": "Comfort Double Room - Breakfast included in the price ",
                    "price": "402.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8232,
                    "hotel_data_id": 938,
                    "room_basis": "RO",
                    "room_type": "Double Room with Sofa Bed ",
                    "price": "408.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8233,
                    "hotel_data_id": 938,
                    "room_basis": "BB",
                    "room_type": "Double Room with Sofa Bed - Breakfast included in the price ",
                    "price": "436.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                }
            ]
        },
        "outbound_flight": {
            "id": 825,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-12T16:10:00.000000Z",
            "arrival": "2024-06-12T17:50:00.000000Z",
            "price": "26.28",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.28, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-12T17:50:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-12T16:10:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        },
        "inbound_flight": {
            "id": 826,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-14T16:30:00.000000Z",
            "arrival": "2024-06-14T18:10:00.000000Z",
            "price": "26.26",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.26, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-14T18:10:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-14T16:30:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        }
    },
    {
        "id": 819,
        "outbound_flight_id": 825,
        "inbound_flight_id": 826,
        "hotel_data_id": 939,
        "commission": "20.00",
        "total_price": "72.54",
        "created_at": "2024-04-13T13:49:33.000000Z",
        "updated_at": "2024-04-13T13:49:33.000000Z",
        "package_config_id": null,
        "hotel_data": {
            "id": 939,
            "hotel_id": 109189,
            "check_in_date": "2024-06-12",
            "number_of_nights": 2,
            "room_count": 1,
            "adults": 2,
            "children": 0,
            "package_config_id": 0,
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "hotel": {
                "id": 109189,
                "hotel_id": 30072,
                "name": "SOMERSET ROLAND GARDENS APARTMENTS",
                "address": "121 Old Brompton Rd, London, GB",
                "phone": "0044 207 3416800",
                "fax": "0044 207 3416801",
                "stars": 4,
                "stars_id": 7,
                "longitude": "-0.18073",
                "latitude": "51.49085",
                "is_apartment": 1,
                "giata_code": "57640",
                "city_id": 1133,
                "city": "LONDON (UK)",
                "iso_code": "GB",
                "country": "UNITED KINGDOM",
                "country_id": 32,
                "review_score": null,
                "review_count": null,
                "destination_id": null,
                "created_at": "2023-09-07T11:14:58.000000Z",
                "updated_at": "2023-09-07T11:14:58.000000Z",
                "description": null,
                "hotel_photos": []
            },
            "offers": [
                {
                    "id": 8234,
                    "hotel_data_id": 939,
                    "room_basis": "RO",
                    "room_type": "Studio ",
                    "price": "312.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8235,
                    "hotel_data_id": 939,
                    "room_basis": "RO",
                    "room_type": "Deluxe Studio ",
                    "price": "374.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8236,
                    "hotel_data_id": 939,
                    "room_basis": "RO",
                    "room_type": "Standard Studio",
                    "price": "408.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8237,
                    "hotel_data_id": 939,
                    "room_basis": "RO",
                    "room_type": "Studio - De Luxe",
                    "price": "433.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8238,
                    "hotel_data_id": 939,
                    "room_basis": "RO",
                    "room_type": "One-Bedroom Apartment ",
                    "price": "436.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8239,
                    "hotel_data_id": 939,
                    "room_basis": "RO",
                    "room_type": "Two-Bedroom Apartment ",
                    "price": "518.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8240,
                    "hotel_data_id": 939,
                    "room_basis": "RO",
                    "room_type": "Premium Apartment",
                    "price": "679.00",
                    "reservation_deadline": "2024-06-10",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                }
            ]
        },
        "outbound_flight": {
            "id": 825,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-12T16:10:00.000000Z",
            "arrival": "2024-06-12T17:50:00.000000Z",
            "price": "26.28",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.28, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-12T17:50:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-12T16:10:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        },
        "inbound_flight": {
            "id": 826,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-14T16:30:00.000000Z",
            "arrival": "2024-06-14T18:10:00.000000Z",
            "price": "26.26",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.26, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-14T18:10:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-14T16:30:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        }
    },
    {
        "id": 820,
        "outbound_flight_id": 825,
        "inbound_flight_id": 826,
        "hotel_data_id": 940,
        "commission": "20.00",
        "total_price": "72.54",
        "created_at": "2024-04-13T13:49:33.000000Z",
        "updated_at": "2024-04-13T13:49:33.000000Z",
        "package_config_id": null,
        "hotel_data": {
            "id": 940,
            "hotel_id": 89150,
            "check_in_date": "2024-06-12",
            "number_of_nights": 2,
            "room_count": 1,
            "adults": 2,
            "children": 0,
            "package_config_id": 0,
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "hotel": {
                "id": 89150,
                "hotel_id": 30094,
                "name": "EUROPE PLAYA MARINA",
                "address": "Paseo De Illetas 68, Calvia, ES",
                "phone": "0034-971-402611",
                "fax": "34971402800",
                "stars": 4,
                "stars_id": 7,
                "longitude": "2.593035",
                "latitude": "39.539884",
                "is_apartment": 0,
                "giata_code": "3374",
                "city_id": 7331,
                "city": "PALMA DE MALLORCA-ALL LOCATIONS",
                "iso_code": "ES",
                "country": "SPAIN",
                "country_id": 24,
                "review_score": null,
                "review_count": null,
                "destination_id": null,
                "created_at": "2023-09-07T11:14:56.000000Z",
                "updated_at": "2023-09-07T11:14:56.000000Z",
                "description": null,
                "hotel_photos": []
            },
            "offers": [
                {
                    "id": 8241,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "DOUBLE SUPERIOR 2 ADULTS",
                    "price": "353.00",
                    "reservation_deadline": "2024-06-07",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8242,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "SUPERIOR DOUBLE/TWIN WITH BALCONY",
                    "price": "354.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8243,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "Superior Double Room Balcony",
                    "price": "366.00",
                    "reservation_deadline": "2024-06-07",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8244,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "DOUBLE SUPERIOR SEA VIEW 2 ADULTS",
                    "price": "401.00",
                    "reservation_deadline": "2024-06-07",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8245,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "SEA VIEW SUPERIOR DOUBLE/TWIN WITH BALCONY",
                    "price": "403.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8246,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "Superior Double Room Balcony Sea View",
                    "price": "417.00",
                    "reservation_deadline": "2024-06-07",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8247,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "Twin/Double room - Superior",
                    "price": "418.00",
                    "reservation_deadline": "2024-06-07",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8248,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "Twin/Double room - Superior Sea View",
                    "price": "439.00",
                    "reservation_deadline": "2024-06-07",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8249,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "JUNIOR SUITE SIDE SEA VIEW 2 ADULTS",
                    "price": "481.00",
                    "reservation_deadline": "2024-06-07",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8250,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "JUNIOR SUITE WITH BALCONY",
                    "price": "483.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8251,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "Junior Suite Partial Sea View",
                    "price": "500.00",
                    "reservation_deadline": "2024-06-07",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8252,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "JUNIOR SUITE",
                    "price": "555.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8253,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "JUNIOR SUITE SEA VIEW 2 ADULTS",
                    "price": "578.00",
                    "reservation_deadline": "2024-06-07",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8254,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "SEA VIEW JUNIOR SUITE WITH BALCONY",
                    "price": "581.00",
                    "reservation_deadline": "2024-06-09",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8255,
                    "hotel_data_id": 940,
                    "room_basis": "BB",
                    "room_type": "Junior Suite Sea View",
                    "price": "600.00",
                    "reservation_deadline": "2024-06-07",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                }
            ]
        },
        "outbound_flight": {
            "id": 825,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-12T16:10:00.000000Z",
            "arrival": "2024-06-12T17:50:00.000000Z",
            "price": "26.28",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.28, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-12T17:50:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-12T16:10:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        },
        "inbound_flight": {
            "id": 826,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-14T16:30:00.000000Z",
            "arrival": "2024-06-14T18:10:00.000000Z",
            "price": "26.26",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.26, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-14T18:10:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-14T16:30:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        }
    },
    {
        "id": 821,
        "outbound_flight_id": 825,
        "inbound_flight_id": 826,
        "hotel_data_id": 941,
        "commission": "20.00",
        "total_price": "72.54",
        "created_at": "2024-04-13T13:49:33.000000Z",
        "updated_at": "2024-04-13T13:49:33.000000Z",
        "package_config_id": null,
        "hotel_data": {
            "id": 941,
            "hotel_id": 122267,
            "check_in_date": "2024-06-12",
            "number_of_nights": 2,
            "room_count": 1,
            "adults": 2,
            "children": 0,
            "package_config_id": 0,
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "hotel": {
                "id": 122267,
                "hotel_id": 30075,
                "name": "LIBERTY",
                "address": "Via San Vito 40, Catania, IT",
                "phone": "39095 311651",
                "fax": "095 7158199",
                "stars": 4,
                "stars_id": 7,
                "longitude": "15.080998",
                "latitude": "37.507367",
                "is_apartment": 0,
                "giata_code": "69949",
                "city_id": 478,
                "city": "CATANIA (SICILY)",
                "iso_code": "IT",
                "country": "ITALY",
                "country_id": 37,
                "review_score": null,
                "review_count": null,
                "destination_id": null,
                "created_at": "2023-09-07T11:15:00.000000Z",
                "updated_at": "2023-09-07T11:15:00.000000Z",
                "description": null,
                "hotel_photos": []
            },
            "offers": [
                {
                    "id": 8256,
                    "hotel_data_id": 941,
                    "room_basis": "BB",
                    "room_type": "Standard Double or Twin Room NON-REFUNDABLE",
                    "price": "359.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8257,
                    "hotel_data_id": 941,
                    "room_basis": "BB",
                    "room_type": "Twin/Double room",
                    "price": "382.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8258,
                    "hotel_data_id": 941,
                    "room_basis": "BB",
                    "room_type": "Standard Double or Twin Room",
                    "price": "399.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8259,
                    "hotel_data_id": 941,
                    "room_basis": "BB",
                    "room_type": "Deluxe Double or Twin Room NON-REFUNDABLE",
                    "price": "420.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8260,
                    "hotel_data_id": 941,
                    "room_basis": "BB",
                    "room_type": "Twin/Double room",
                    "price": "423.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8261,
                    "hotel_data_id": 941,
                    "room_basis": "BB",
                    "room_type": "Deluxe Double or Twin Room",
                    "price": "466.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8262,
                    "hotel_data_id": 941,
                    "room_basis": "BB",
                    "room_type": "Family Room 2 Bedrooms Balcony Tower NON-REFUNDABLE",
                    "price": "473.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8263,
                    "hotel_data_id": 941,
                    "room_basis": "BB",
                    "room_type": "Family Room 2 Bedrooms Balcony Tower",
                    "price": "524.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8264,
                    "hotel_data_id": 941,
                    "room_basis": "BB",
                    "room_type": "Deluxe Family Room - Non-refundable - Breakfast included in the price ",
                    "price": "539.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8265,
                    "hotel_data_id": 941,
                    "room_basis": "BB",
                    "room_type": "Suite NON-REFUNDABLE",
                    "price": "555.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8266,
                    "hotel_data_id": 941,
                    "room_basis": "BB",
                    "room_type": "Deluxe Family Room - Breakfast included in the price ",
                    "price": "599.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8267,
                    "hotel_data_id": 941,
                    "room_basis": "BB",
                    "room_type": "Suite",
                    "price": "617.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8268,
                    "hotel_data_id": 941,
                    "room_basis": "BB",
                    "room_type": "Deluxe Suite - Non-refundable - Breakfast included in the price ",
                    "price": "634.00",
                    "reservation_deadline": "2024-04-12",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                },
                {
                    "id": 8269,
                    "hotel_data_id": 941,
                    "room_basis": "BB",
                    "room_type": "Deluxe Suite - Breakfast included in the price ",
                    "price": "704.00",
                    "reservation_deadline": "2024-06-08",
                    "remark": null,
                    "created_at": "2024-04-13T13:49:33.000000Z",
                    "updated_at": "2024-04-13T13:49:33.000000Z"
                }
            ]
        },
        "outbound_flight": {
            "id": 825,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-12T16:10:00.000000Z",
            "arrival": "2024-06-12T17:50:00.000000Z",
            "price": "26.28",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.28, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-12T17:50:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-12T16:10:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        },
        "inbound_flight": {
            "id": 826,
            "origin": "TIA",
            "destination": "FCO",
            "departure": "2024-06-14T16:30:00.000000Z",
            "arrival": "2024-06-14T18:10:00.000000Z",
            "price": "26.26",
            "airline": "Wizz Air Malta",
            "stop_count": 0,
            "extra_data": "{\"price\": 26.26, \"origin\": \"TIA\", \"airline\": \"Wizz Air Malta\", \"arrival\": \"2024-06-14T18:10:00+00:00\", \"carriers\": [-30596], \"departure\": \"2024-06-14T16:30:00+00:00\", \"stopCount\": 0, \"destination\": \"FCO\", \"timeBetweenFlights\": []}",
            "created_at": "2024-04-13T13:49:33.000000Z",
            "updated_at": "2024-04-13T13:49:33.000000Z",
            "package_config_id": 0
        }
    }
]


