import revive_payload_client_4sVQNw7RlN from "/var/www/hima-trips/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/hima-trips/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/hima-trips/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/var/www/hima-trips/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/var/www/hima-trips/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/hima-trips/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/hima-trips/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/hima-trips/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/hima-trips/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_m9SjywKgWm from "/var/www/hima-trips/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_SCHuOseEny from "/var/www/hima-trips/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_XJTWbJLGEl from "/var/www/hima-trips/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_zOJ1kxPqxh from "/var/www/hima-trips/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/hima-trips/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_GFfDXud5Cr from "/var/www/hima-trips/plugins/api.ts";
import clickOutside_ZB3Eg0wOVk from "/var/www/hima-trips/plugins/clickOutside.ts";
import fontawesome_klhsrycjcK from "/var/www/hima-trips/plugins/fontawesome.js";
import sentry_3AyO8nEfhE from "/var/www/hima-trips/plugins/sentry.ts";
import vue_paginate_hdRk3l7HKU from "/var/www/hima-trips/plugins/vue-paginate.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_m9SjywKgWm,
  defaults_SCHuOseEny,
  siteConfig_XJTWbJLGEl,
  inferSeoMetaPlugin_zOJ1kxPqxh,
  chunk_reload_client_UciE0i6zes,
  api_GFfDXud5Cr,
  clickOutside_ZB3Eg0wOVk,
  fontawesome_klhsrycjcK,
  sentry_3AyO8nEfhE,
  vue_paginate_hdRk3l7HKU
]