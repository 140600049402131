export const appHead = {"link":[{"rel":"icon","href":"/favicon.ico","sizes":"any"}],"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"facebook-domain-verification","content":"nbjlpyymo9oe1dpa3qtrpcw3iw105a"},{"property":"og:type","content":"website"}],"style":[],"script":[{"children":"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WRR2Z6RS');"}],"noscript":[{"children":"<iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-WRR2Z6RS\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>","body":true}],"htmlAttrs":{"lang":"sq-AL"}}

export const appRootId = "himaTrips"

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#himaTrips'

export const viewTransition = false