import { default as indexS48A4F92CJMeta } from "/var/www/hima-trips/pages/index.vue?macro=true";
import { default as packageQY3SiqwjM3Meta } from "/var/www/hima-trips/pages/package.vue?macro=true";
import { default as search_45_91origin_93_45to_45_91destination_93MyN7sqyGhgMeta } from "/var/www/hima-trips/pages/search-[origin]-to-[destination].vue?macro=true";
export default [
  {
    name: indexS48A4F92CJMeta?.name ?? "index",
    path: indexS48A4F92CJMeta?.path ?? "/",
    meta: indexS48A4F92CJMeta || {},
    alias: indexS48A4F92CJMeta?.alias || [],
    redirect: indexS48A4F92CJMeta?.redirect,
    component: () => import("/var/www/hima-trips/pages/index.vue").then(m => m.default || m)
  },
  {
    name: packageQY3SiqwjM3Meta?.name ?? "package",
    path: packageQY3SiqwjM3Meta?.path ?? "/package",
    meta: packageQY3SiqwjM3Meta || {},
    alias: packageQY3SiqwjM3Meta?.alias || [],
    redirect: packageQY3SiqwjM3Meta?.redirect,
    component: () => import("/var/www/hima-trips/pages/package.vue").then(m => m.default || m)
  },
  {
    name: search_45_91origin_93_45to_45_91destination_93MyN7sqyGhgMeta?.name ?? "search-origin-to-destination",
    path: search_45_91origin_93_45to_45_91destination_93MyN7sqyGhgMeta?.path ?? "/search-:origin()-to-:destination()",
    meta: search_45_91origin_93_45to_45_91destination_93MyN7sqyGhgMeta || {},
    alias: search_45_91origin_93_45to_45_91destination_93MyN7sqyGhgMeta?.alias || [],
    redirect: search_45_91origin_93_45to_45_91destination_93MyN7sqyGhgMeta?.redirect,
    component: () => import("/var/www/hima-trips/pages/search-[origin]-to-[destination].vue").then(m => m.default || m)
  }
]