<template>
    <div>
<!--        <ModalWrapper/>-->
        <NuxtLayout>
            <NuxtPage/>
        </NuxtLayout>
        <Footer/>
        <FloatWhatsapp v-if="route.path !== '/'"/>
    </div>
</template>
<script setup lang="ts">
// import ModalWrapper from "~/components/layout/ModalWrapper.vue";
import Footer from "~/components/layout/Footer.vue";
import FloatWhatsapp from "~/components/FloatWhatsapp.vue";
const mainStore = useMainStore();
const route = useRoute();

onMounted(async () => {
    if(route.path.split('-')[0] !== '/search'){
        await mainStore.actGetOrigins();
    }
})
</script>
<style scoped>

</style>
