import {format} from 'date-fns'
//import {testLiveData} from "~/constants";
export const useMainStore = defineStore('main', () => {
    const {$api} = useNuxtApp();
    const {buildStorageUrl} = useUtils();


    //state
    const batchId = ref('');
    const packagesAreFromLiveSearch = ref(false)
    const fromServer = ref(false)
    const currTravelPackage = ref<FullPackage | null>(null);
    const loadingCurrPackage = ref(true);
    const loadingDeals = ref(true);
    const imageCollectionIsOpen = ref(false);
    const packageToScrollTo = ref<number | null>(null)
    const allOrigins = ref<Origin[]>([]);
    const allDestinations = ref<Destination[]>([]);
    const availableDates = ref<string[]>([])
    const sort = ref<string[]>(['total_price', 'ASC']);
    const originSearch = ref('');
    const destinationSearch = ref('');
    const old_price_range = ref([0, 0]);
    const price_range = ref([0, 0]);
    const selectedOrigin = ref<Origin | null>(null);
    const selectedDestination = ref<Destination | null>(null);
    const selectedDate = ref<Date | null>(null);
    const selectedNights =  ref<number | null>(null);
    const selectedSort = ref<{title: string, value: number}>({title: 'Rekomandimit', value: 1});
    const selectedPage = ref(1);
    const searchTotal = ref(0);
    const priceMode = ref(true);
    const roomBasisFilter = ref('');
    const reviewFilter = ref<number[]>([]);
    const hotelStarsFilter = ref<number[]>([]);
    const travelersNumber = ref<Travelers>({
        adults: 2,
        children: 0,
        infants: 0
    })
    const filterData = ref<FilterData | null>(null)
    const priceFilterChanged = ref(false)

    const travelPackages = ref<FullPackage[] | null>(null);
    const loadingPackages = ref(true);
    const destinationOffers = ref<TravelOffer[]>([])
    const destinationPackages = ref<{[key: string]: RecommendPackage}>();

    const currSearchParams = ref<{origin: string, destination: string, date: Date, nights: number} | null>(null)

    //getters
    const disableFilters = computed(() => {
        return packagesAreFromLiveSearch.value && !travelPackages.value?.length && filterData.value;
    })

    const minMaxPrice = computed(() => {
        return filterData.value?.minMaxPrices || {min_price: 0, max_price: 0}
    })

    const getTravelersInfo = computed(() => {
        const allChildrenNo = travelersNumber.value.children + travelersNumber.value.infants;
        return `${travelersNumber.value.adults} të rritur${allChildrenNo ? ` dhe ${allChildrenNo} fëmijë` : ''}`;
    })


    const getSearchParams = computed(() => {
        if(selectedNights.value && selectedOrigin.value && selectedDestination.value && sort.value.length === 2 && selectedDate.value && selectedPage.value) {
            const [sort_by, sort_order] = sort.value
            return {
                batch_id: batchId.value || null,
                nights: selectedNights.value,
                checkin_date: format(selectedDate.value, 'yyyy-MM-dd'),
                origin_id: selectedOrigin.value.id,
                destination_id: selectedDestination.value.id,
                page: selectedPage.value,
                sort_by,
                sort_order,
                ...travelersNumber.value,
            }
        }
        return undefined;
    })

    const getLiveSearchPaginatedParams = () => {
        if(getSearchParams.value){
            const {sort_by, sort_order, page} = getSearchParams.value;
            let hasPriceRange = false;
            if(price_range.value[0] || price_range.value[1]){
                const [currMin, currMax] = price_range.value;
                if(currMin !== minMaxPrice.value.min_price || currMax !== minMaxPrice.value.max_price){
                    hasPriceRange = true;
                }
            }
            return {
                batch_id: batchId.value,
                sort_by,
                sort_order,
                page,
                ...(hasPriceRange ? {price_range: price_range.value} : {}),
                stars: hotelStarsFilter.value,
                review_scores: reviewFilter.value,
                ...(roomBasisFilter.value ? {room_basis: [roomBasisFilter.value]} : {})
            }
        }
        return {}
    }

    const getLiveSearchParams = computed(() => {
        if(selectedNights.value && selectedOrigin.value && selectedDestination.value && selectedDate.value && selectedPage.value) {
            return {
                nights: selectedNights.value,
                date: format(selectedDate.value, 'yyyy-MM-dd'),
                origin: selectedOrigin.value.id,
                destination: selectedDestination.value.id,
                ...travelersNumber.value
            }
        }
        return undefined;
    })

    const getPackageImages = computed(() => {
        return (pckg: FullPackage | null) => pckg?.hotel_data.hotel?.hotel_photos.map(x => buildStorageUrl(x.file_path)) || [];
    })


    //actions
    const actResetParams = () => {
        originSearch.value = '';
        destinationSearch.value = '';
        selectedDestination.value = null;
        selectedOrigin.value = null;
        selectedDate.value = null;
        selectedNights.value = null;
        selectedPage.value = 1;
    }

    const actResetFilters = () => {
        roomBasisFilter.value = '';
        hotelStarsFilter.value = [];
        reviewFilter.value = [];
        price_range.value = [0, 0];
        old_price_range.value = [0, 0];
        if(filterData.value){
            filterData.value = {...filterData.value, minMaxPrices: {min_price: '0', max_price: '0'}}
        }
    }

    const actSetOrigin = (origin: Origin) => {
        selectedOrigin.value = origin;
    }

    const actSetDestination = (destination: Destination | null) => {
        selectedDestination.value = destination;
    }

    const setPackages = (packages: FullPackage[]) => {
        travelPackages.value = packages;
        loadingPackages.value = false;
    }

    const actLiveSearch = async () => {
        // const el = document.getElementById('resultsSection');
        // el?.scrollIntoView({behavior: 'smooth'});
        // console.log('getLiveSearchParams.value', getLiveSearchParams.value)
        console.log('doiing live search');
        // alert('stopppp')
        if(getLiveSearchParams.value) {
            packagesAreFromLiveSearch.value = true;
            batchId.value = '';
            loadingPackages.value = true;
            try {
                const {data} = await $api.post('/live-search', getLiveSearchParams.value)
                console.log('dataaaaaaa', data)
                batchId.value = data.batch_id;
            } catch (e: any) {
                console.log('travel nulllllll')
                travelPackages.value = null;
                if(e?.response){
                    if(e.response?.status === 404) {
                        console.log('No package found')
                    } else {
                        console.error(e, 'errrorrr')
                    }
                }
            } finally {
                // el?.scrollIntoView({behavior: 'smooth'});
            }
            // await $api.post('/live-search', getLiveSearchParams.value)
            //     .then((response) => {
            //         console.log('responseeeee:::', response)
            //         if(response.data){
            //             const {batch_id} = response.data;
            //             console.log(batch_id, 'batch_id, max, min')
            //             batchId.value = batch_id;
            //             console.log('batchId.value', batchId.value)
            //             // const {data, total, current_page} = response.data
            //             // travelPackages.value = data;
            //             // selectedPage.value = current_page;
            //             // searchTotal.value = total;
            //         }
            //     }).catch((e) => {
            //         travelPackages.value = null;
            //         if(e.response){
            //             if(e.response?.status === 404) {
            //                 console.log('No package found')
            //             } else {
            //                 console.error(e, 'errrorrr')
            //             }
            //         }
            //     })
            // loadingPackages.value = false;
        }
    }

    const actGetPackages = async () => {
        // console.log('aaaaaaaaaaaaaaaaaaaaaaa')
        // let el = null
        // try {
        //     el = document.getElementById('resultsSection');
        //     el?.scrollIntoView({behavior: 'smooth'});
        // } catch (e) {
        //
        // }

        if(getSearchParams.value) {
            console.log('getting data')
            packagesAreFromLiveSearch.value = false;
            loadingPackages.value = true;
            try{
                actSetPaginatedFilterData();
                const {data} = await $api.post('/live-search-paginated', getLiveSearchPaginatedParams());
                travelPackages.value = data.data;
                searchTotal.value = data.total
            } catch (e) {
                console.log(e)
            } finally {
                loadingPackages.value = false;
                // el?.scrollIntoView({behavior: 'smooth'});
            }
            // await $api('/live-search-paginated', getLiveSearchPaginatedParams())
            //     .then(({data}) => {
            //         console.log(data.data, 'paginated data')
            //         travelPackages.value = data.data;
            //         searchTotal.value = data.total
            //     }).catch(e => {
            //         console.log(e)
            //     }).finally(() => {
            //         loadingPackages.value = false;
            //         el?.scrollIntoView({behavior: 'smooth'});
            //     })

        }
    }

    const actSetPaginatedFilterData = async () => {
        try {
            const {data} = await $api.post('/filter-data', getLiveSearchPaginatedParams());
            const {minMaxPrices, ...filters} = data;
            const [currMin, currMax] = price_range.value;
            const {min_price, max_price} = filterData.value?.minMaxPrices || {min_price: 0, max_price: 0};

            if(currMin !== Number(min_price) || currMax !== Number(max_price)){
                console.log('daaaaaaaaaaaa::::', {currMin, currMax})
                old_price_range.value = [currMin, currMax];
                filterData.value = {...filterData.value, ...filters};
            } else {
                minMaxPrices.min_price = Math.floor(minMaxPrices.min_price);
                minMaxPrices.max_price = Math.ceil(minMaxPrices.max_price);
                price_range.value = [minMaxPrices.min_price, minMaxPrices.max_price];
                old_price_range.value = [minMaxPrices.min_price, minMaxPrices.max_price];
                filterData.value = {...filters, minMaxPrices};
            }
        } catch (e) {

        } finally {

        }
    }

    const actGetPackageById = async (id: any) => {
        loadingCurrPackage.value = true;
        await $api.get(`/packages/${id}`)
            .then((response) => {
                console.log('get by id response', response)
                // if(response?.data){
                    currTravelPackage.value = response.data;
                // }
            })
            .catch((e) => {
                console.log(e, 'get by id errorrr')
            })
        console.log('finished get package')
        loadingCurrPackage.value = false;
    }

    const actGetOrigins = async () => {
        const response = await $api.get('/origins')
        if(response.data) {
            allOrigins.value = response.data;
        } else {
            console.log(response, 'error actGetOrigins')
        }
    }

    const actGetDestinations = async (id?: number) => {
        if(!selectedOrigin.value && !id) return;
        try {
            const response = await $api.get(`/destinations/${id || selectedOrigin.value?.id}/plain`);
            allDestinations.value = response.data;
        } catch (e) {
            console.log(e, 'error getting destinations', `--- /destinations/${id || selectedOrigin.value?.id}/plain`)
        }
    }

    const actSetAllDestinations = (destinations: Destination[]) => {
        allDestinations.value = destinations;
    }

    const actGetDeals = async (id: number | string, sort : string = 'price', otherParams: any = {}) => {
        loadingDeals.value = true
        await $api.post(`/destinations/${id}`, {
                sort,
                ...otherParams
        })
            .then((response) => {
                console.log('response', response)
                destinationOffers.value = response.data
            })
            .catch((e) => {
                console.log(e, 'actGetDeals')
            })
        loadingDeals.value = false
    }

    const actGetDestinationPackages = async (destinationID: number, origId: number, sort : string = 'price') => {
        await $api.post(`destinations/${destinationID}/packages`, {
            origin: origId,
            sort
        })
            .then((response) => {
                destinationPackages.value = response.data;
            }).catch((e) => {
                console.log('destination packages', e)
            })
    }

    const actGetAvailableDates = async (date:  {}) => {
        console.log({
            origin_id: selectedOrigin.value?.id,
            destination_id: selectedDestination.value?.id,
            ...date
        })
        try {
            const response = await $api.post('/packages/available-dates', {
                origin_id: selectedOrigin.value?.id,
                destination_id: selectedDestination.value?.id,
                ...date
            })
            availableDates.value = response.data;
        } catch (e) {

        } finally {

        }
    }

    const actSetCurrSearchParams = () => {
        currSearchParams.value = {
            origin: selectedOrigin.value?.city || 'Nisja',
            destination: selectedDestination.value?.city || 'Mbërritja',
            date: selectedDate.value || new Date(),
            nights: selectedNights.value || 0
        }
    }

    return {
        allDestinations,
        allOrigins,
        availableDates,
        batchId,
        currTravelPackage,
        destinationOffers,
        destinationPackages,
        destinationSearch,
        fromServer,
        getPackageImages,
        getSearchParams,
        loadingCurrPackage,
        loadingPackages,
        loadingDeals,
        originSearch,
        priceMode,
        searchTotal,
        selectedDate,
        selectedDestination,
        priceFilterChanged,
        selectedNights,
        travelersNumber,
        selectedOrigin,
        sort,
        price_range,
        old_price_range,
        reviewFilter,
        roomBasisFilter,
        hotelStarsFilter,
        selectedPage,
        getTravelersInfo,
        packageToScrollTo,
        selectedSort,
        disableFilters,
        imageCollectionIsOpen,
        currSearchParams,
        travelPackages,
        packagesAreFromLiveSearch,
        filterData,
        minMaxPrice,
        actGetAvailableDates,
        actGetDestinations,
        actGetDestinationPackages,
        actGetDeals,
        actGetOrigins,
        actGetPackageById,
        actGetPackages,
        actLiveSearch,
        actResetParams,
        actResetFilters,
        actSetDestination,
        actSetAllDestinations,
        actSetCurrSearchParams,
        actSetOrigin,
        setPackages,
        actSetPaginatedFilterData,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useMainStore, import.meta.hot))
}
