<script setup lang="ts">
const openLink = () => {
    const link = `https://api.whatsapp.com/send/?phone=${355695161381}&text=`
    const message = `Pershendetje Hima\nInteresohem rreth ofertave\n Link: ${window.location.href}`
    window.open(link + encodeURIComponent(message), "_blank");
}

</script>

<template>
    <div class="floatWhatsapp cursor-pointer flex sm:hidden justify-center items-center" @click="openLink">
        <nuxt-icon name="whatsapp"/>
    </div>
</template>

<style scoped>
.floatWhatsapp{
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    background-color:#25d366;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    font-size:40px;
    box-shadow: 2px 2px 3px #999;
    z-index:100;
}
</style>
