import {format, formatDuration} from 'date-fns';
import sq from 'date-fns/locale/sq/index';

export const useUtils = () => {

    const runtimeConfig = useRuntimeConfig();
    const mainStore = useMainStore();

    const multipleSearchFilter = (array: any[], filterKeys: string | string[], keyword: string) => {
        const checkIdx = (option: string) => option.toLowerCase().indexOf(keyword);
        return array.filter(opt => {
            if (Array.isArray(filterKeys)) {
                for (let x = 0; x < filterKeys.length; x++) {
                    const idx = checkIdx(opt[filterKeys[x] || '']);
                    if (idx >= 0) return true;
                }
                return false;
            } else {
                return checkIdx(opt[filterKeys || '']);
            }
        })
    }

    const displayNights = (nights: number | null | undefined) => {
        if (!nights) return '';
        return nights + (nights > 1 ? ' Netë' : ' Natë')
    }

    const removeTimezone = (date: any) => {
        const parseDate = new Date(date)
        const userTimezoneOffset = parseDate.getTimezoneOffset() * 60000;
        return new Date(parseDate.valueOf() + userTimezoneOffset)
    }

    const formatDateSQ = (date: Date | number | string | undefined | null, formatStr: string = 'PP') => {
        if(!date) return JSON.stringify(date);
        return format(removeTimezone(date), formatStr, {
            locale: sq
        })
    }
    const formatDurationSQ = (duration: any, options: string[] = ['hours', 'minutes']) => {
        return formatDuration(duration, {
            format: options,
            locale: sq
        })
    }

    const isInViewport = (el: HTMLElement) => {
        if (!el) return false;
        const rect = el.getBoundingClientRect();
        // returns true or false based on whether the element is in viewport
        return rect.top === 0;
    }

    const roomBasisInfo = (code: string) => {
        switch (code) {
            case 'BB':
                return 'Mëngjesi i përfshirë';
            case 'CB':
                return 'Mengjes kontinental';
            case 'AI':
                return 'All Inclusive';
            case 'FB':
                return 'Full board';
            case 'HB':
                return 'Half board';
            case 'RO':
                return 'Vetëm fjetje';
            case 'BD':
                return 'Darka e përfshirë';
            default:
                return `${code}`;
        }

    }

    const round = (value: number) => {
        return Math.ceil(value / 10) * 10;
    }

    const   getFirstOffer = (hotel_data: FullHotel | null | undefined, roomBasisFilter?: string | undefined | null) => {
        if(hotel_data?.offers?.length){
            return (!!roomBasisFilter ? hotel_data.offers.find((element) => element.room_basis === roomBasisFilter) : hotel_data?.offers[0])
        }
        return null;
    }

    const roomBasisInfoPoint = (code: string | undefined) => {
        switch (code) {
            case 'BB':
                return 'Fjetja dhe Mengjesi i Perfshire';
            case 'CB':
                return 'Mengjes kontinental';
            case 'AI':
                return 'Mengjesi + Dreka + Darka dhe Pije pa limit';
            case 'FB':
                return 'Mengjesi + Dreke + Darke dhe Pije gjate vakteve';
            case 'HB':
                return 'Fjetja + Mengjesi Dhe Darka e Perfshire';
            case 'RO':
                return 'Vetëm fjetje ne Hotel';
            case 'BD':
                return 'Darka e përfshirë';
            default:
                return '';
        }
    }

    const sendMessageToWp = (pckg: FullPackage, offer: Offer | null | undefined) => {
        if(!offer){
            return console.error('cant find offer')
        }
        const flightData = pckg.outbound_flight ? JSON.parse(pckg.outbound_flight.extra_data) : null;
        let seg = flightData?.segments || []
        if(typeof seg === 'string'){
            seg = JSON.parse(seg)
        }
        console.log(seg, 'seg[0]seg[0]')
        const {origin} = seg[0];
        const number = '355695161381';
        // const info = ['Udhetime', 'Mar,  20   Shtator   2023', '2','http://localhost:3000/package']
        const link = `https://api.whatsapp.com/send/?phone=${number}&text=`
        const pckgTitle = pckg.hotel_data.hotel.name;
        const pckgNight = displayNights(pckg.hotel_data.number_of_nights);
        const pckgBasis = roomBasisInfo(offer.room_basis);
        const travelers = mainStore.getTravelersInfo;
        const price = Math.ceil(Number(offer?.total_price_for_this_offer || 0));
        const url = window.location.href
        console.log(pckgNight, 'flightdaaatta')
        let message = `Përshëndetje Hima\nJam i interesuar për udhëtimin ${pckg.hotel_data.hotel.city}\nNisja: Nga ${origin?.name || pckg.outbound_flight.origin}, ${formatDateSQ(pckg.hotel_data.check_in_date, 'dd LLLL yyyy')}, ${pckgNight} , ${pckgBasis}\nHotel: ${pckgTitle}\nUdhëtarët: ${travelers}\nÇmimi: ${price}€\nLinku: ${url}\n`

        if(!travelers || !price || !url){
            console.error(`Whatsapp Link has problems:: ${JSON.stringify({travelers, price, url})}`)
        }


        return link + encodeURIComponent(message)
        // alert(message.replaceAll(' ', ' ').replaceAll(' \n', `\n`))
        // window.open(link + message.replace(/\s+/g, ''), "_blank");
    }

    const buildStorageUrl = (file_path: string) => {
        return `extImages/${file_path}`;
        // return `${runtimeConfig.public.storageUrl}/${file_path}`
    }

    const getAllDatesInMonth = (year: number, month: number) => {
        let startDate = new Date(year, month - 1, 1); // month is 0-indexed
        let endDate = new Date(year, month, 1);

        let dates = [];
        while (startDate < endDate) {
            dates.push(new Date(startDate)); // clone the date object
            startDate.setDate(startDate.getDate() + 1);
        }

        return dates;
    }

    const geQueryName = (place: Origin | Destination) => {
        return place.name.toLowerCase().replaceAll(' ', '-')
    }


    const roomBasisFilterInfo = (code: string) => {
        switch (code) {
            case '':
                return 'Any';
            case 'BB':
                return 'Mëngjes';
            case 'AI':
                return 'All Inclusive';
            case 'FB':
                return 'Full board';
            case 'HB':
                return 'Half board';
            case 'RO':
                return 'Vetëm fjetje';
            case 'BD':
                return 'Darka e përfshirë';
            default:
                return `${code}`;
        }

    }

    return {
        multipleSearchFilter,
        removeTimezone,
        isInViewport,
        displayNights,
        formatDateSQ,
        formatDurationSQ,
        buildStorageUrl,
        sendMessageToWp,
        roomBasisInfo,
        roomBasisFilterInfo,
        getAllDatesInMonth,
        geQueryName,
        roomBasisInfoPoint,
        round,
        getFirstOffer
    }
}
