import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronDown, faChevronLeft, faChevronRight, faUtensils, faX, faMagnifyingGlass, faSliders, faPlane, faHotel } from '@fortawesome/free-solid-svg-icons'
import {faCircleXmark} from '@fortawesome/free-regular-svg-icons'

library.add(faChevronDown, faUtensils,faChevronLeft, faChevronRight, faX, faCircleXmark, faMagnifyingGlass, faSliders, faPlane, faHotel)

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon, {})
})
