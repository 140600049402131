import type { RouterConfig } from "@nuxt/schema";

export default <RouterConfig>{
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            if(to.path !== from.path){
                return {
                    top: 0
                }
            }
        }
    }
};
